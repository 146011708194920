var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      class: "form-edit-component form-edit-component-" + _vm.size,
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.attributeDialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "jtl-entity-dialog-component" },
        [
          _c("jtl-form", {
            ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
            on: { change: _vm.formOnChange },
          }),
          _vm.attributeType !== _vm.AttributeType.SERVER
            ? _c(
                "a-tabs",
                { attrs: { type: "card" } },
                _vm._l(_vm.driverList, function (item) {
                  return _c(
                    "a-tab-pane",
                    {
                      key: item.identifier,
                      attrs: { tab: item.name, "force-render": "" },
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticClass: "ant-form-item-label",
                              attrs: { span: 4 },
                            },
                            [_c("div", [_vm._v("对象属性类型")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticClass: "ant-form-item-label",
                              attrs: { span: 5 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ant-form-item",
                                  staticStyle: {
                                    paddingRight: "6px",
                                    "line-height": "32px",
                                  },
                                },
                                [_vm._v("属性类型:")]
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "ant-form-item-control-wrapper",
                              attrs: { span: 12 },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择属性类型",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: item.objectTypePropIdentifier,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "objectTypePropIdentifier",
                                        $$v
                                      )
                                    },
                                    expression: "item.objectTypePropIdentifier",
                                  },
                                },
                                _vm._l(
                                  item.objectTypeProps || [],
                                  function (option) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: option.identifier,
                                        attrs: { value: option.identifier },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(option.name)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.attributeDialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _vm.viewMode !== _vm.ViewModeType.VIEW
            ? _c(
                "jtl-button",
                { attrs: { type: "primary", "click-prop": _vm.saveAttribute } },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }