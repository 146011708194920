var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerCloseHandler },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "things-template-detail-component table-component productInfo",
        },
        [
          _c(
            "a-tabs",
            {
              attrs: { type: "card", "default-active-key": "1" },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "产品信息" } },
                [
                  !_vm.vision
                    ? _c(
                        "jtl-button",
                        {
                          staticClass: "description-title-button m-b-16 m-l-16",
                          attrs: { type: "primary" },
                          on: { click: _vm.editClick },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _c(
                    "a-descriptions",
                    { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "模板名称", span: 1 } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.thingsTemplateModel.name) + " "
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "标识符", span: 1 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.thingsTemplateModel.identifier) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "更新时间", span: 1 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.thingsTemplateModel.modifiedTime
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "描述", span: 3 } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.thingsTemplateModel.remark) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "功能定义" } },
                [
                  _c("things-function-list-component", {
                    staticClass: "tab-margin-16",
                    attrs: {
                      "things-function-model": _vm.thingsTemplateModel,
                      "entity-type": _vm.ThingsEntityType.TEMPLATE,
                      version: _vm.vision,
                    },
                    on: { reload: _vm.reload },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }