






































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import ThingsFunctionListComponent from '../things-function/things-function-list.vue';
import ThingsTemplateService from '@common-src/service/things-template';
import { ThingsTemplateEntityModel } from '@common-src/entity-model/things-template-entity';
import { ThingsEntityType, ViewModeType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';

@Component({
    components: {
        'things-function-list-component': ThingsFunctionListComponent
    }
})
export default class ThingsTemplateDetailDrawer extends DrawerComponent {
    ThingsEntityType = ThingsEntityType;
    thingsTemplateModel: ThingsTemplateEntityModel = new ThingsTemplateEntityModel();
    thingsTemplateId: string;
    tabIndex: string = '1';
    vision = '';
    drivers: any = undefined;

    // created() {
    //     this.init();
    // }

    // get TemplateId() {
    //     return this.$route.params.id;
    // }

    drawerCloseHandler() {
        this.drawerVisible = false;
        this.$emit('close');
    }

    drawerShow(model: ThingsTemplateEntityModel, tabIndex?:string, vision?: string) {
        this.drawerOpen(model.id, model.name);
        this.drivers = model.drivers;
        this.init(model.id, vision);
        this.tabIndex = tabIndex || '1';
        this.vision = vision || '';
    }

    init(modelId: string, vision?:string) {
        this.thingsTemplateId = modelId;
        this.startFullScreenLoading();
        ThingsTemplateService.retrieve({
            templateId: modelId,
            version: vision || null
        })
            .then(res => {
                this.thingsTemplateModel = res;
                this.thingsTemplateModel.drivers = this.drivers;
                this.drawerTitle = res.name;
            })
            .catch(error => {
                this.thingsTemplateModel = new ThingsTemplateEntityModel();
                this.thingsTemplateModel.drivers = this.drivers;
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        this.init(this.thingsTemplateId);
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<ThingsTemplateEntityModel>).dialogOpen(this.thingsTemplateModel, ThingsTemplateService, ViewModeType.UPDATE);
    }
}
