
















































import { Component, Prop, Watch } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlOptionModel } from '@common-src/model/form-control';
import { ViewModeType, AttributeType } from '@common-src/model/enum';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import DeviceDriverService from '@common-src/service3/device-driver';
import ThingsAttributeService from '@common-src/service/things-attribute';
import ThingsTemplateService from '@common-src/service/things-template';

@Component({
    components: {

    }
})
export default class ThingsAttributeDialog extends FormDialogComponent<any> {
    driverList: Array<any> = null;
    thingsModel: ThingsModelTypeModel = new ThingsModelTypeModel();
    disabled: boolean = false;
    attributeType: AttributeType = null;
    AttributeType = AttributeType;

    @Prop({ default: 'large' })
    size: string;
    formOnChange(formControlModel: FormControlOptionModel) {
        this.attributeType = this.jtlForm.formModel.attributeType;
        this.$emit('change', formControlModel);
    }

    /**
     * 打开弹窗
     * @param model 数据模型
     * @param service 增删改查服务
     * @param needGetDetail 是否需要调用详情接口
     */
    attributeDialogOpen(model: any, service: any, viewMode: ViewModeType, needGetDetail = false, version?: string): any {
        this.attributeType = model.attributeType;
        this.thingsModel = model.thingsModelTypeModel;
        let formModel = model;
        viewMode === ViewModeType.VIEW ? this.disabled = true : this.disabled = false;
        if (model.id) {
            ThingsAttributeService.retrieveVersion({
                id: model.id,
                version: version
            }).then(res => {
                this.driverList = _.get(res, 'driverPointDetails');
                _.forEach(this.driverList, r => {
                    r.objectTypePropIdentifier = _.get(r, 'objectTypePropIdentifier') || undefined;
                });
                formModel = res;
            }).finally(() => {
                this.dialogOpen(formModel, service, viewMode, needGetDetail);
            });
        } else if (model.entityId) {
            DeviceDriverService.getThingsTemplateDriverDetail(model.entityId).then(res => {
                this.driverList = res;
                _.forEach(this.driverList, r => {
                    r.objectTypePropIdentifier = _.get(r, 'objectTypePropIdentifier') || undefined;
                });
            });
            this.dialogOpen(model, service, viewMode, needGetDetail);
        }
    }
    /**
     * 点击弹窗保存事件
     * 提交表单数据
     */
    async saveAttribute(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }

        // if (this.jtlForm.formModel.attributeType === AttributeType.CLIENT && !_.isEmpty(this.driverList) && _.some(this.driverList, r => !r.objectTypePropIdentifier)) {
        //     this.showMessageWarning('属性类型为必填项');
        //     return;
        // }

        this.jtlForm.formModel.attrDriver = _.map(this.driverList, r => {
            let variables = {};
            _.forEach(r.variables, (item) => {
                variables[item.identifier] = item.value || '';
            });
            return {
                'identifier': _.get(r, 'identifier'),
                'name': _.get(r, 'name'),
                'driverObjectTypePropIdentifier': _.get(r, 'objectTypePropIdentifier'),
                'variables': variables,
                'pollInterval': _.get(r, 'commonConfig.pollInterval') || '',
                'coefficient': _.get(r, 'commonConfig.coefficient') || '',
                'dataConversionExpression': _.get(r, 'commonConfig.dataConversionExpression') || {}
            };
        });

        return this.jtlForm.submitForm().then((ret) => {
            this.attributeDialogClose();
            switch (this.viewMode) {
                case ViewModeType.NEW:
                    this.$emit('dialogOK', { viewMode: this.viewMode, res: Object.assign({}, this.jtlForm.formModel, { id: ret }) });
                    break;
                case ViewModeType.UPDATE:
                    this.$emit('dialogOK', { viewMode: this.viewMode, res: this.jtlForm.formModel });
                    break;
            }
            this.showMessageSuccess('保存成功');
            // this.$emit('dialogOK', { viewMode: this.viewMode, res: ret });
        }).catch(err => {
            throw err;
        });
    }

    attributeDialogClose() {
        this.driverList = null;
        this.dialogClose();
    }
}
